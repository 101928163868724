<script setup lang="ts">

const props = withDefaults(defineProps<{
  visible: boolean,
  message: string,
  confirmFunc:() => void,
  title: string,
}>(), {
  visible: false,
  message: '<span class="text-primary">删除后，其所有数据将无法恢复</span>',
  title: '提示'
})
const emits = defineEmits<{(event: 'confirmDelete'): void, (event: 'update:visible', value: boolean): void}>()
const dialogVisible = computed({
  get: () => props.visible,
  set: (value: boolean) => void emits('update:visible', value)
})
function cancelBtn() {
  dialogVisible.value = false
}
</script>

<template>
  <div class="w-auto h-auto flex justify-center">
    <client-only>
      <el-dialog
        v-model="dialogVisible"
        width="500"
      >
        <template #header>
          <span class="text-assist1 text-xl font-bold">{{ title }}</span>
        </template>
        <div class="w-full h-auto flex flex-col">
          <div
            class="text-[15px] text-assist1"
            v-html="message"
          />
        </div>
        <template #footer>
          <el-button
            class="gs_primary_btn_default"
            @click="cancelBtn"
          >
            取消
          </el-button>
          <el-button
            class="gs_primary_btn_confirm"
            @click="confirmFunc"
          >
            确定
          </el-button>
        </template>
      </el-dialog>
    </client-only>
  </div>
</template>

<style scoped lang="scss">

</style>
